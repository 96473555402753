<template>
  <span>
    <v-row class="mx-auto">
      <v-col
        v-for="chart in chartsData"
        :key="chart.nomeIndicador"
        cols="12"
        sm="6"
        md="4"
        xl="3"
      >
        <v-card>
          <v-card-title>
            {{ chart.nomeIndicador | toUpperCase }}
          </v-card-title>

          <v-card-text class="body-2">
            {{ chart.descricaoIndicador }}
          </v-card-text>

          <v-card-text style="max-height: 185px; overflow: auto;">
            <v-simple-table class="table-indicadores" dense>
              <template v-slot:default>
                <thead v-if="visaoGrafico === 'anual'">
                  <tr>
                    <th>Ano</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <thead v-else>
                  <tr>
                    <th>Mês</th>
                    <th v-for="ano in anos" :key="ano">
                      {{ ano }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="visaoGrafico === 'anual'">
                  <tr v-for="ano in anos" :key="ano">
                    <td>{{ ano }}</td>
                    <td>
                      {{
                        ano in dadosIndicadores
                          ? dadosIndicadores[ano][chart.nomeIndicador]
                          : "-" | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="mes in meses" :key="mes">
                    <td>{{ mes }}</td>

                    <td v-for="ano in anos" :key="ano">
                      {{
                        `${mes}-${ano}` in dadosIndicadores
                          ? dadosIndicadores[`${mes}-${ano}`][
                              chart.nomeIndicador
                            ]
                          : "-" | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-text>
            <highcharts
              :options="chart.chartOptions"
              :deepCopyOnUpdate="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  import Highcharts from "highcharts";
  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  HighchartsNoData(Highcharts);

  export default {
    name: "ChartsTemasComercial",
    props: {
      tema: {
        type: String,
        required: true,
      },
      competencias: {
        type: Array,
        required: true,
      },
      anos: {
        type: Array,
        required: true,
      },
      meses: {
        type: Array,
        required: true,
      },
      visaoGrafico: {
        type: String,
        required: true,
      },
      dadosIndicadores: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      indicadoresPorTema: {
        Faturamento: [
          "com_005",
          "com_006",
          "com_007",
          "com_008",
          "com_009",
          "com_010",
          "com_011",
          "com_012",
          "com_013",
          "com_014",
          "com_015",
          "com_016",
          "com_017",
          "com_018",
          "com_019",
          "com_020",
          "com_021",
          "com_022",
          "com_023",
          "com_024",
          "com_025",
          "com_026",
          "com_027",
          "com_028",
        ],
        LigacaoComObras: [
          "com_029",
          "com_030",
          "com_031",
          "com_032",
          "com_033",
          "com_034",
          "com_035",
          "com_036",
        ],
        DanosEletricos: ["com_037", "com_038", "com_039", "com_040"],
        SubstituicaoDeMedidores: ["com_041"],
        EstruturaDeAtendimento: [
          "com_042",
          "com_043",
          "com_044",
          "com_045",
          "com_046",
          "com_047",
          "com_048",
          "com_049",
          "com_050",
        ],
        RecuperacaoDeReceita: [
          "com_051",
          "com_052",
          "com_053",
          "com_054",
          "com_055",
          "com_056",
          "com_057",
        ],
        SuspensaoIndevida: ["com_058", "com_059"],
      },
      descricaoPorIndicador: {
        com_005:
          "Quantidade de unidades consumidoras com contrato vigente, mesmo sem faturamento.",
        com_006:
          "Quantidade de unidades consumidoras com fatura regular emitida.",
        com_007:
          "Quantidade total de faturas emitidas para cobrança do faturamento regular.",
        com_008: "Quantidade de faturas emitidas sem leitura.",
        com_009:
          "Quantidade de faturas emitidas sem leitura devido a impedimento de acesso.",
        com_010:
          "Quantidade de faturas emitidas sem leitura devido a Situação de Emergência, Calamidade Pública ou Força Maior.",
        com_011:
          "Quantidade de faturas emitidas sem leitura devido a Leitura Plurimensal.",
        com_012:
          "Quantidade de faturas emitidas sem leitura devido à aplicação do art. 228.",
        com_013:
          "Quantidade de faturas emitidas sem leitura devido a encerramento contratual.",
        com_014:
          "Quantidade de faturas emitidas sem leitura devido a ausência temporária do sistema de medição ou deficiência no sistema.",
        com_015:
          "Quantidade de faturas com revisão e alteração do valor original cobrado.",
        com_016:
          "Quantidade de faturas com ajuste entre valores faturados e consumidos.",
        com_017: "Quantidade de faturas com ajuste por faturamento incorreto.",
        com_018:
          "Quantidade de faturas com ajuste por faturamento incorreto a maior com devolução em dobro.",
        com_019:
          "Quantidade de faturas com ajuste por faturamento incorreto a maior com devolução simples por motivo atribuível ao consumidor.",
        com_020:
          "Quantidade de faturas com ajuste por faturamento incorreto a maior com devolução simples por motivo atribuível a terceiro.",
        com_021:
          "Quantidade de faturas com ajuste por faturamento por impedimento de acesso.",
        com_022:
          "Quantidade de faturas com ajuste por Situação de Emergência, Calamidade Pública ou Força Maior.",
        com_023:
          "Quantidade de faturas emitidas sem leitura, com faturamento pela média aritmética dos ciclos anteriores.",
        com_024:
          "Quantidade de faturas emitidas sem leitura, com faturamento pelo custo de disponibilidade.",
        com_025:
          "Quantidade de faturas emitidas com leitura realizada pela distribuidora.",
        com_026:
          "Quantidade total de faturas emitidas com Autoleitura do medidor realizada por representante da unidade consumidora.",
        com_027:
          "Quantidade de consumidores abrangidos pela Leitura Plurimensal.",
        com_028: "Quantidade de consumidores com Autoleitura.",
        com_029:
          "Quantidade de restituições realizadas pela distribuidora ao interessado.",
        com_030:
          "Quantidade de restituições realizadas pela distribuidora ao interessado com prazo estabelecido descumprido.",
        com_031:
          "Quantidade de restituições pendentes de serem realizadas pela distribuidora ao interessado.",
        com_032:
          "Quantidade de restituições pendentes de serem realizadas pela distribuidora ao interessado com prazo vencido.",
        com_033:
          "Valor total de restituições realizadas pela distribuidora ao interessado.",
        com_034:
          "Valor total de restituições realizadas pela distribuidora ao interessado com prazo estabelecido descumprido.",
        com_035:
          "Valor total de restituições pendentes de serem realizadas pela distribuidora ao interessado.",
        com_036:
          "Valor total de restituições pendentes de serem realizadas pela distribuidora ao interessado com prazo vencido.",
        com_037:
          "Quantidade de solicitações de ressarcimento de danos elétricos efetuadas.",
        com_038:
          "Quantidade de solicitações de ressarcimento de danos elétricos concluídas com indeferimento.",
        com_039:
          "Montante total pendente de pagamento aos consumidores relativo a ressarcimento de danos elétricos.",
        com_040:
          "Montante total pago aos consumidores relativo a ressarcimento de danos elétricos.",
        com_041:
          "Quantidade de unidades consumidoras com substituição de equipamentos de medição.",
        com_042:
          "Quantidade de postos de atendimento presenciais disponibilizados aos consumidores.",
        com_043: "Quantidade de dias classificados como 'normais'.",
        com_044:
          "Quantidade de dias classificados como 'caso fortuito e força maior'.",
        com_045:
          "Quantidade total de atendimentos realizados nos postos de atendimento presenciais durante dias 'normais'.",
        com_046:
          "Quantidade total de atendimentos nos postos de atendimento presenciais em dias de caso fortuito e força maior.",
        com_047:
          "Média, em minutos, do tempo dos atendimentos nos postos de atendimento presenciais em dias normais.",
        com_048:
          "Média, em minutos, do tempo dos atendimentos nos postos de atendimento presenciais em dias de caso fortuito e força maior.",
        com_049:
          "Quantidade total de atendimentos nos postos de atendimento presenciais em dias normais, com tempo de espera superior a 30 minutos.",
        com_050:
          "Quantidade total de atendimentos nos postos de atendimento presenciais em dias de caso fortuito e força maior, com tempo de espera superior a 30 minutos.",
        com_051:
          "Quantidade total de inspeções realizadas pela distribuidora no sistema de medição para caracterizar procedimentos irregulares.",
        com_052:
          "Quantidade total de Termos de Ocorrência e Inspeção (TOI) emitidos em razão das inspeções realizadas pela distribuidora no sistema de medição.",
        com_053:
          "Quantidade total de Termos de Ocorrência e Inspeção (TOI) emitidos pela distribuidora, que resultaram em cobrança ao consumidor devido a procedimentos irregulares comprovados.",
        com_054:
          "Quantidade total de Termos de Ocorrência e Inspeção (TOI) encerrados pelas distribuidoras, considerando os encerramentos com e sem cobrança, para caracterizar procedimentos irregulares.",
        com_055:
          "Quantidade total de Termos de Ocorrência e Inspeção (TOI) emitidos para cobrança ao consumidor devido a deficiências na medição.",
        com_056:
          "Quantidade total de autoreconnections com cobrança para recuperação de receita.",
        com_057:
          "Quantidade de unidades consumidoras com suspensão do fornecimento devido a inadimplemento.",
        com_058:
          "Quantidade de suspensões indevidas de energia elétrica efetuadas no mês de referência.",
        com_059:
          "Montante total pago aos consumidores no mês de referência como compensação por suspensão indevida.",
      },
      chartsData: [],
    }),
    computed: {
      //
    },
    created() {
      this.setChartsData();
    },
    methods: {
      setChartsData() {
        let newChartsData = [];

        this.indicadoresPorTema[this.tema].forEach((indicador) => {
          newChartsData.push({
            nomeIndicador: indicador,
            descricaoIndicador: this.descricaoPorIndicador[indicador],
            chartOptions: this.getChartOptions(
              indicador,
              this.visaoGrafico,
              this.dadosIndicadores
            ),
          });
        });

        this.chartsData = newChartsData;
      },
      getChartOptions(indicador, tipoGrafico, dadosIndicadores) {
        return {
          chart: {
            type: tipoGrafico === "anual" ? "column" : "spline",
            zoomType: "xy",
            height: 275,
          },
          colors: [
            "#003a63",
            "#554584",
            "#a1478b",
            "#e04c75",
            "#ff6f4c",
            "#ffa600",
          ],
          credits: { enabled: false },
          title: { text: null },
          subtitle: { text: null },
          legend: { enabled: tipoGrafico === "mensal" ? true : false },
          xAxis: {
            categories: tipoGrafico === "mensal" ? this.meses : this.anos,
            crosshair: true,
            title: { text: tipoGrafico === "mensal" ? "Mês" : "Ano" },
          },
          yAxis: {
            title: { text: null },
          },
          plotOptions:
            tipoGrafico === "mensal"
              ? {
                  series: {
                    marker: {
                      enabledThreshold: 16,
                      radius: 3,
                    },
                    animation: false,
                  },
                }
              : null,
          lang: {
            noData: "Sem dados para exibição",
          },
          series: this.getChartSeries(indicador, tipoGrafico, dadosIndicadores),
        };
      },
      getChartSeries(indicador, tipoGrafico, dadosIndicadores) {
        if (tipoGrafico === "mensal") {
          return this.anos.map((ano) => {
            return {
              name: ano,
              animation: false,
              data: this.meses
                .map((mes) => {
                  return `${mes}-${ano}` in dadosIndicadores
                    ? {
                        x: this.meses.indexOf(mes),
                        y: Number(dadosIndicadores[`${mes}-${ano}`][indicador]),
                      }
                    : null;
                })
                .filter((val) => val !== null && val.y !== null && val.y > 0),
            };
          });
        } else {
          return {
            name: "Valor",
            animation: false,
            data: this.anos
              .map((ano) => {
                return ano in dadosIndicadores
                  ? {
                      x: this.anos.indexOf(ano),
                      y: Number(dadosIndicadores[ano][indicador]),
                    }
                  : null;
              })
              .filter((val) => val !== null && val.y !== null && val.y > 0),
          };
        }
      },
    },
    watch: {
      dadosIndicadores() {
        this.setChartsData();
      },
    },
  };
</script>

<style>
  .table-indicadores table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
    text-align: end;
  }
  .table-indicadores table thead tr th {
    font-size: 12px;
    height: 25px;
    text-align: end !important;
    font-weight: 500;
  }
</style>
